import React from "react";
import AppContactBox from "../components/contact/contact-box";
import AppPageTitle from "../components/page-title";
import { Map, Marker, Point } from "pigeon-maps";
import { Helmet } from "react-helmet";
import { useTheme } from "@emotion/react";
import AppContainer from "../components/container";

const coordinate: Point = [56.18715, 9.57998];

const ContactPage = () => {
  const theme = useTheme();

  return (
    <>
      <Helmet title="RWS | Kontakt"></Helmet>
      <AppPageTitle>Kontakt</AppPageTitle>
      <div style={{ flex: "1 1 auto" }}>
        <AppContactBox />
      </div>
      <Map height={300} defaultCenter={coordinate} defaultZoom={16}>
        <Marker
          width={50}
          anchor={coordinate}
          color={theme.colors.secondary.main}
        />
      </Map>
    </>
  );
};

export default ContactPage;

import styled from "@emotion/styled";
import React from "react";

const H1 = styled.h1(({ theme }) => ({
  fontSize: "6vw",
  fontWeight: 600,
  borderBottom: `3px solid ${theme.colors.primary.main}`,
  width: "fit-content",
  maxWidth: "80vw",
  padding: `0 ${theme.spacing(1)}px`,
  margin: "40px auto 25px auto",
  [theme.mq.tablet]: {
    fontSize: "4vw",
  },
  [theme.mq.desktop]: {
    fontSize: 50,
  },
}));

const AppPageTitle: React.FC = ({ children }) => {
  return <H1>{children}</H1>;
};

export default AppPageTitle;
